::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ccc;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover
{
    background: #aaa;
    border-radius: 6px;
}

html, body {
    font-size: 14px;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid black;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

#root {
    min-height: 100%;
    display: flex;
}

#root:empty {
    display: initial;
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 4em;
    min-height: 4em;
    height: 4em;
    border: 0.3em solid rgba(255, 255, 255, 0.2);
    border-left: 0.3em solid #E50B14;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
